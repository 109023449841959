import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import MyCarousel from '../../components/Carousel/MyCarousel';
import './Rooms.css';
import MyFooter from '../../components/Footer/MyFooter';
import MyCopyright from '../../components/Copyright/MyCopyright';

// Fallback image URL
const fallbackImage = '/default-image.jpg';

const SingleRoom = () => {
  const { roomType } = useParams();
  const [roomData, setRoomData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const response = await axios.get('http://localhost/HOTEL-MANAGEMENT-SYSTEM-master/backend/roomes.php', {
          params: { roomType }
        });
        const room = response.data.find(r => r.roomType === roomType);
        if (room) {
          setRoomData(room);
        } else {
          setError('Room not found');
        }
      } catch (err) {
        setError('Failed to fetch room data');
      } finally {
        setLoading(false);
      }
    };

    fetchRoomData();
  }, [roomType]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!roomData) return <p>No room data available</p>;

  // Construct the full URL for the image
  const imageUrl = roomData.image
    ? `http://localhost/HOTEL-MANAGEMENT-SYSTEM-master/backend/${roomData.image1}`
    : fallbackImage;

  return (
    <div>
      <MyCarousel title={`Booking Ticket Now`} />
      <center>
        <h1 className='room-details-heading'>Details of Booking Sit</h1>
      </center>
      <div className='singleRoom-cards'>
        <div className="card">
        <img src={`http://localhost/HOTEL-MANAGEMENT-SYSTEM-master/backend/${roomData.image1}`} className="card-img-top" alt="{product.product_name}" />
        </div>

        <div className="card">
        <img src={`http://localhost/HOTEL-MANAGEMENT-SYSTEM-master/backend/${roomData.image2}`} className="card-img-top" alt="{product.product_name}" />
        </div>

        <div className="card">
        <img src={`http://localhost/HOTEL-MANAGEMENT-SYSTEM-master/backend/${roomData.image3}`} className="card-img-top" alt="{product.product_name}" />
        </div>
      </div>

      <div className='single-room-detail'>
        <div className='single-room-detail-para'>
          <h1>Details</h1> 
          <p>{roomData.details}</p>
        </div>

        <div className='single-room-detail-list'>
          <h1>Info</h1>
          <p>
          
            Price: Rs {roomData.price} <br /><br />
            Size: {roomData.size} <br /><br />
            Max Capacity: {roomData.capacity} Person <br /><br />
            Pets: {roomData.pets ? 'Allowed' : 'Not Allowed'} <br /><br />
            Free Breakfast: {roomData.breakfast ? 'Included' : 'Not Included'} <br /><br />
          </p>
        </div>
      </div>

      <div className='single-room-detail'>
        <div className='single-room-detail-list2'>
          <h1>Extras</h1>
          <p>{roomData.extrac_1}<br />
            "Soft Towel" <br />
            "AC" <br />
          </p>
        </div>

        <div className='single-room-detail-list2'>
          <h1 className='white'>Extras</h1>
          <p>{roomData.extrac_2}<br />
            "Amazing view" <br />
            "Internet" <br />
          </p>
        </div>

        <div className='single-room-detail-list2'>
          <h1 className='white'>Extras</h1>
          <p>{roomData.extrac_3}<br />
            Complimentary refreshments <br />
            "Adequate safety/security" <br />
          </p>
        </div>
      </div>

      <center>
        <div>
          <Link to={`/booknow/${roomType}`}><button className='btn bookNow-btn'>sit Now</button></Link>
        </div>
      </center>

      <MyFooter />
      <MyCopyright />
    </div>
  );
};

export default SingleRoom;
