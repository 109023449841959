import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import MyCarousel from '../../components/Carousel/MyCarousel';
import MyFooter from '../../components/Footer/MyFooter';
import MyCopyright from '../../components/Copyright/MyCopyright';
import './Booknow.css';

const fallbackImage = '/default-image.jpg';

const Booknow = () => {
  const { roomType } = useParams();
  const [roomData, setRoomData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cnic, setCnic] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [persons, setPersons] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [totalDays, setTotalDays] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const response = await axios.get('http://localhost/HOTEL-MANAGEMENT-SYSTEM-master/backend/roomes.php', {
          params: { roomType }
        });
        const room = response.data.find(r => r.roomType === roomType);
        if (room) {
          setRoomData(room);
        } else {
          setError('Room not found');
        }
      } catch (err) {
        setError('Failed to fetch room data');
      } finally {
        setLoading(false);
      }
    };

    fetchRoomData();
  }, [roomType]);

  useEffect(() => {
    if (startDate && endDate && roomData) {
      const calculateDays = () => {
        const diffTime = endDate - startDate;
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      };

      const days = calculateDays();
      setTotalDays(days);
      setTotalAmount(days * roomData.price);
    }
  }, [startDate, endDate, roomData]);

  const handleBooking = async (e) => {
    e.preventDefault(); // Prevent default form submission

    if (!roomData) return alert("Room details are not available.");
    if (persons <= 0 || persons > roomData.capacity) {
      return alert("Please check the number of persons. It should be greater than 0 and within the room's capacity.");
    }
    if (totalDays <= 0) {
      return alert("Please select valid start and end dates.");
    }
    if (
      name &&
      address &&
      cnic &&
      email &&
      phone &&
      startDate &&
      endDate
    ) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('cnic', cnic);
      formData.append('address', address);
      formData.append('email', email);
      formData.append('persons', persons);
      formData.append('roomType', roomData.roomType);
      formData.append('startDate', startDate.toLocaleDateString());
      formData.append('endDate', endDate.toLocaleDateString());
      formData.append('totalPrice', totalAmount);
      formData.append('days', totalDays);
      formData.append('capacity', roomData.capacity);
      formData.append('status', 'Pending');

      try {
        const response = await axios.post('http://localhost/HOTEL-MANAGEMENT-SYSTEM-master/backend/bookings.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        console.log('Response:', response.data); // Log the response data for debugging

        if (response.data.success) {
          alert('Booking Successful! You can view your bookings.');
          navigate('/');
        } else {
          alert('Failed to book: ' + (response.data.error || 'Unknown error'));
        }
      } catch (error) {
        console.error('Error saving booking:', error);
        alert('Failed to book. Please try again later.');
      }
    } else {
      alert('Please fill in all required fields.');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!roomData) return <p>No room data available</p>;

  const imageUrl = roomData.image1
    ? `http://localhost/HOTEL-MANAGEMENT-SYSTEM-master/backend/${roomData.image1}`
    : fallbackImage;

  return (
    <div>
      <MyCarousel title={`Ticket Booking Now`} />
      <center>
        <h1 className='room-details-heading'>Book a Sit</h1>
      </center>
      <div className='singleRoom-cards'>
        <div className="card">
          <img src={imageUrl} className="card-img-top" alt={roomData.roomType} />
        </div>
      </div>

      <div className='single-room-detail'>
        <div className='single-room-detail-para'>
          <h1>Details</h1>
          <p>{roomData.details}</p>
        </div>
        <div className='single-room-detail-list'>
          <h1>Info</h1>
          <p>
            Price: BDT {roomData.price} <br /><br />
            Size: {roomData.size} <br /><br />
            Max Capacity: {roomData.capacity} Person <br /><br />
            Pets: {roomData.pets ? 'Allowed' : 'Not Allowed'} <br /><br />
            Free Breakfast: {roomData.breakfast ? 'Included' : 'Not Included'} <br /><br />
          </p>
        </div>
      </div>

      <div className='booking-form'>
        <h1>Book Now</h1>
        <div className='form-group'>
          <label htmlFor="name">Name</label>
          <input
            name='name'
            type="text"
            className="form-control"
            value={name}
            id="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Full name"
            required
          />
        </div>

        <div className='form-group'>
          <label htmlFor="address">Address</label>
          <input
            name='address'
            type="text"
            className="form-control"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            id="address"
            placeholder="Your address"
            required
          />
        </div>

        <div className='form-group'>
          <label htmlFor="cnic">CNIC Number</label>
          <input
            name='cnic'
            type="text"
            className="form-control"
            value={cnic}
            onChange={(e) => setCnic(e.target.value)}
            id="cnic"
            placeholder="CNIC"
            maxLength={13}
            required
          />
        </div>

        <div className='form-group'>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name='email'
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            placeholder="Your email"
            required
          />
        </div>

        <div className='form-group'>
          <label htmlFor="phone">Phone</label>
          <PhoneInput
            name='phone'
            defaultCountry="BD"
            className="form-control"
            id="phone"
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
          />
        </div>

        <div className='form-group'>
          <label htmlFor="persons">Number of Persons</label>
          <input
            type="number"
            name='persons'
            value={persons}
            className="form-control"
            onChange={(e) => setPersons(e.target.value)}
            id="persons"
            placeholder="No. of persons"
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor="startDate">Start Date</label>
          <DatePicker
            name='startDate'
            selected={startDate}
            onChange={date => setStartDate(date)}
            minDate={new Date()}
            className="form-control"
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor="endDate">End Date</label>
          <DatePicker
            name='endDate'
            selected={endDate}
            onChange={date => setEndDate(date)}
            minDate={new Date()}
            className="form-control"
          />
        </div>
        <div className='form-group'>
          <label htmlFor="totalDays">Total Days</label>
          <input
            name='days'
            type="text"
            className="form-control"
            value={totalDays}
            id="totalDays"
            readOnly
          />
        </div>
        <div className='form-group'>
          <label htmlFor="totalAmount">Total Amount</label>
          <input
            name='totalPrice'
            type="text"
            className="form-control"
            value={`BDT ${totalAmount}`}
            id="totalAmount"
            readOnly
          />
        </div>
      
        <center>
          <button onClick={handleBooking} className='btn bookNow-btn'>
            Confirm Booking
          </button>
        </center>
      </div>

      <MyFooter />
      <MyCopyright />
    </div>
  );
};

export default Booknow;
