import React from 'react'
import './MyCopyright.css'
const MyCopyright = () => {
    return (
        <div className='copyrightBar'>
            <p>Copyright ©2024 Biman Bangladesh Airlines by Ferdaus Ahmed Nasim  </p>
        </div>
    )
}

export default MyCopyright