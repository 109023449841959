import React from 'react'
import './About.css'
import hotelVideo from '../../imgs/video.mp4';
import {Link} from 'react-router-dom'
import MyFooter from '../../components/Footer/MyFooter'
import MyCopyright from '../../components/Copyright/MyCopyright'

const About = () => {
    return (
        <div>
            <section class="heading">
                <video autoPlay loop class="video-background" muted plays-inline>
                    <source src={hotelVideo} type="video/mp4" />
                </video>

                <center>
                    <div class="welcome-msg ">
                        <h1> Biman Bangladesh Airlines </h1>
                        <p>
                            
                        Biman Bangladesh Airlines, the national carrier of Bangladesh, was established in 1972, marking its emergence as a key player in the country's aviation sector. With its primary hub at Dhaka's Hazrat Shahjalal International Airport, Biman offers a range of domestic and international flights. The airline plays a crucial role in connecting Bangladesh with global destinations, fostering economic growth and cultural exchange. Despite facing challenges such as fleet modernization and competition, Biman remains integral to Bangladesh’s connectivity and national pride. Its efforts to enhance service quality and operational efficiency reflect its commitment to better serving passengers. As it navigates the evolving aviation landscape, Biman Bangladesh Airlines continues to be a symbol of progress and connectivity for the nation.
                        </p>
                       <Link to="/singleRoom/single"> <a class="btn btn-book btna"> Book Room </a> </Link>
                       <Link to="/"> <a class="btn btn-home btna"> Return to Home</a></Link>
                    </div>

                </center>
            </section>
            <MyFooter/>
            <MyCopyright/>
        </div>
    )
}

export default About
